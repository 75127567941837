import { KycAuth, KycAuthForm, KycRequest, KycVerifies } from "@/modules/types/kyc.ts";
import { STATUSCODE } from "@/modules/types/statusCode";
import { ImageUploadResponse } from "@/modules/types/uploadImage.ts";

// 使用composition API模式定义store
export const useKycStore = defineStore("KycStore", () => {
  // 初始状态
  const initState = {
    kycVerify:null, //驗證狀態
    kycAuth: null,
    kycAuthForm: {
      id_front_img:null,
      id_back_img:null,
      bank_account_img:null,
      id_number: "",
      name: "",
      bank_account: "",
      bank_code:'',
      phone:'',
      sms_code:''
    },
  };

  //state
  const kycAuth = ref<KycAuth | null>(initState.kycAuth)
  const kycVerifies = ref<KycVerifies | null>(initState.kycVerify)
  const kycAuthForm = ref<KycAuthForm>(initState.kycAuthForm)

  //gatters
  const kycStatus = computed(() => {
    //請求失敗時
    if(kycVerifies.value?.req_failed) return STATUSCODE.disable
    //1.還沒有kycAuth表示還沒有申請；或者請求kyc資訊失敗
    if (!kycAuth.value) return STATUSCODE.default;
    //2.身分證或銀行帳號驗證有任何一個在pending就返回審核中
    if(kycVerifies.value?.id_number === STATUSCODE.pending || 
      kycVerifies.value?.bank_account === STATUSCODE.pending) return STATUSCODE.pending
    //3.身分證且銀行帳號有任何一個被拒絕就返回拒絕
    if(kycVerifies.value?.id_number === STATUSCODE.rejected || 
      kycVerifies.value?.bank_account === STATUSCODE.rejected) return STATUSCODE.rejected
    //4.通通都通過，才返回approved
    if(kycVerifies.value?.phone === STATUSCODE.approved && 
      kycVerifies.value.id_number === STATUSCODE.approved &&
      kycVerifies.value.bank_account === STATUSCODE.approved) return STATUSCODE.approved
    return STATUSCODE.default
  });
  //mutation
  function setKycAuth(payload: KycAuth) {
    kycAuth.value = payload;
  }
  function setKycVerifies(payload: KycVerifies) {
    kycVerifies.value = payload;
  }
  function setIdFrontImg(payload: ImageUploadResponse){
    kycAuthForm.value.id_front_img = payload
  }
  function setIdBackImg(payload: ImageUploadResponse){
    kycAuthForm.value.id_back_img = payload
  }
  function setBankAccountImg(payload: ImageUploadResponse){
    kycAuthForm.value.bank_account_img = payload
  }
  function setBankAccount(payload:string){
    kycAuthForm.value.bank_account = payload
  }
  function setIdNameAndNumber(payload:{name?:string, id_number?:string}){
    if(payload.name) kycAuthForm.value.name = payload.name
    if(payload.id_number) kycAuthForm.value.id_number = payload.id_number
  }

  return {
    //data
    kycAuth,
    kycVerifies,
    kycStatus,
    kycAuthForm,
    //methods
    setKycAuth,
    setKycVerifies,
    setIdFrontImg,
    setIdBackImg,
    setBankAccountImg,
    setBankAccount,
    setIdNameAndNumber
  };
});

/* eslint-disable no-param-reassign */
import fetchGames, { typeLists } from "../service/casinoGames/lists";
import Login, { typeInfo as typeLogin } from "../service/casinoGames/login";
import CreateGame from "../library/GAME/index";
// import { getEnv } from '@/modules/FPKG-20000-Util/env'

type flatGame = Omit<typeLists.VenderItem, "sub_games"> & {
  p26_sgs_code?: string;
  p26_sgs_name?: string;
};

type state = {
  /** 原始遊戲數據 raw Data */ 
  rawData: typeLists.VenderItem[];
  /** 扁平化的 子/主 遊戲列表 */
  flatGames: flatGame[];
  /** 遊戲的啟動資訊 */
  launcher?: Record<string, any>;
  /** 合併扁平化資料後的 子/主 遊戲列表 */
  mergedFlatGames: any[];
  /** 合併資料後的 品牌列表*/
  mergedBrands: any[];
  dialogLauncher: boolean;
};

const state: state = {
  rawData: [],
  flatGames: [],
  launcher: {},
  mergedFlatGames: [],
  mergedBrands: [],
  dialogLauncher: false,
};

export default {
  namespaced: true,
  state,
  mutations: {
    setDialogLauncher: (st: state, isOpen: boolean) => {
      st.dialogLauncher = isOpen;
    },
    setList: (st: state, { flatGames, rawData }) => {
      st.rawData = rawData;
      st.flatGames = flatGames;
    },
    setMergedData: (st: state, { mergedFlatGames, mergedBrands }) => {
      st.mergedFlatGames = mergedFlatGames;
      st.mergedBrands = mergedBrands;
    },
    setLauncher: (
      st: state,
      data: {
        request: typeLogin.request;
        login_info: typeLogin.response;
      },
    ) => {
      const { request, login_info } = data;
      /** 預設找主遊戲 這段仍存在的原因，是因為 TNZE 的靜態結構導致 */
      const list = st.mergedFlatGames;
      let info = list.find(G => G.code === request.p26_ges_code);
      /** 有 subCode 找子遊戲 */
      if (request.p26_sgs_code) {
        info = list.find(
          G => request.p26_sgs_code && G.subCode === request.p26_sgs_code,
        );
      }
      st.launcher = { ...info, ...request, login_info };
    },
  },
  actions: {
    async getList({ commit }: any) {
      try {
        const res = await (await fetchGames()).data.response;
        const flatGames = res.map(setFlat).reduce((cur, next) => [...cur, ...next], []);
        
        // Merge games logic here
        const mergedFlatGames = await Promise.all(flatGames.map(async G => {
          const ARG = { code: G.p26_ges_code, subCode: G.p26_sgs_code };
          const result = await new CreateGame(ARG).initGame();
          return { ...G, ...result };
        }));

        const mergedBrands = await Promise.all(res.map(async G => {
          const result = await new CreateGame({ code: G.p26_ges_code }).initGame();
          return { ...G, ...result };
        }));

        commit("setList", { flatGames, rawData: res });
        commit("setMergedData", { mergedFlatGames, mergedBrands });
      } catch (error) {
        console.error("Error fetching game list:", error);
      }
    },
    /** 取得登入遊戲資訊 */
    async loginGameAction(
      { commit }: any,
      request: typeLogin.request & { activeType?: game_type },
    ) {
      const login_info = await (await Login(request)).data.response;
      commit("setLauncher", { request, login_info });
    },
  },
  getters: {
    /* 所有遊戲的列表，包含了子遊戲 */
    list(st: state) {
      return st.mergedFlatGames as GAME_INFO[];
    },
    /** 遊戲品牌列表 */
    brands(st: state) {
      return st.mergedBrands as BRAND_INFO[];
    },
    launcher(st: state) {
      return st.launcher;
    },
    dialogLauncher(st: state) {
      return st.dialogLauncher;
    },
  },
};

/** 遊戲/子遊戲的扁平化 */
function setFlat(GAME: typeLists.VenderItem) {
  const { sub_games, ...RES_G } = GAME;
  if (!sub_games || (Array.isArray(sub_games) && sub_games.length === 0))
    return [RES_G];
  return sub_games.map(SUB => ({ ...RES_G, ...SUB }));
}

// /**
//  * 印出子遊戲列表，僅在語系檔使用
//  * @param arr 遊戲列表
//  */
// function printSubname(arr: typeLists.response) {
//   const IS_DEV = getEnv('NODE_ENV') === "development";
//   if (!IS_DEV) return;
//   const NAME = "EG_ELECTRONIC";
//   const sub_games = arr.find(g => g.p26_ges_code === NAME)?.sub_games;
//   const result = sub_games
//     ?.map(g => `"${g.p26_sgs_code}": "${g.p26_sgs_name}"`)
//     .join(",\n ");

//   const newWindow = window.open();
//   if (newWindow) {
//     // 在這裡使用 newWindow 物件
//     newWindow.document.write(`<pre>${result}</pre>`);
//   } else {
//     // 處理 newWindow 為 null 的情況
//     console.error("無法打開新視窗");
//   }
// }

/* eslint-disable no-case-declarations, consistent-return, guard-for-in, prefer-destructuring, no-use-before-define, no-restricted-syntax, max-len */
import { getRandomCode } from "@/modules/FPKG-20000-Util/prototypes/TokenCode";
import axios, { AxiosResponse } from "axios";
import VueError, {
  VueErrorStructor,
} from "../FPKG-20000-Util/const/error/vueError";
import { getEnv } from '@/modules/FPKG-20000-Util/env'

export const options = {
  timeout: 150000,
  headers: {
    // 'X-Branch-Source': domain,
  },
  /** 環境變數偵測 */
  baseURL: getEnv('VITE_APP_URL') || "http://api.usdtcasino.cc/",
  // baseURL: getEnv('VITE_APP_URL') || 'http://api.pt6688.cc',
  // baseURL: getEnv('VITE_APP_URL') || 'http://fed-point-api.mirfak.tw',
  // baseURL: getEnv('VITE_APP_URL') || 'http://notfi.hackfbg.net',
};

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.headers.put["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
const Axios = axios.create(options);

/** 抬頭顯示器 */
// const hud = 'hud/front/info';

Axios.interceptors.request.use(
  config => {
    const configData = config;

    const authorization = checkToken();
    configData.headers["x-authorisation"] = authorization;
    configData.headers['X-Requested-With'] = 'XMLHttpRequest';

    return config;
  },
  (error: object) => Promise.reject(error),
);

Axios.interceptors.response.use(
  (response: any) => {
    // const noHud = response.config.url !== `${response.config.baseURL}/${hud}`;
    if (response.status !== 200) {
      return;
    }

    /** 例外的處理 包含了20001 */
    exceptionHandle(response);

    return response;
  },
  (error: object) =>  Promise.reject(error),
);

export default Axios;

/** 進行例外處理動作 */
function exceptionHandle(response: AxiosResponse) {
  switch (response.data.code) {
    case 20001:
      let data = "";
      for (const res in response.data.response) {
        data = data.concat(response.data.response[res][0], "\n");
      }
      const vueError: VueErrorStructor = {
        code: 20001,
        message: data,
      };
      throw new VueError(vueError);
    default:
      break;
  }
}

/** 檢查token的生命週期 */
export function checkToken(): string {
  /** token碼 */
  let auth = localStorage.getItem("x-authorisation");
  /** token誕生日 */
  const token = localStorage.getItem("token");
  if (!auth) {
    auth = getRandomCode(60);
    localStorage.setItem("x-authorisation", auth);
  }
  if (token) {
    localStorage.setItem("token", `${Date.now()}`);
  }

  return auth;
}

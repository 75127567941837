import { useSmsApi } from "@/modules/api/useSmsApi";
import useMessage from "./useMessage";
import { SmsVerifyRequest } from "../types/sms";
export default function useSms(initClock:boolean=true) {
  let CLOCK_ID: NodeJS.Timeout;
  const { fetchSendSmsCode, fetchSmsVerivfy } = useSmsApi();
  const { showMessage } = useMessage();
  const { t } = useI18n();
  const timer = ref(0);

  onMounted(() => {
    if(!initClock) return
    init();
  });
  onBeforeUnmount(() => {
    if(!initClock) return 
    stopClock();
  });

  async function sendSmsVerify(phone: string) {
    timer.value = 60;
    const res = await fetchSendSmsCode({
      phone,
    });
    if (res)
      showMessage({
        message: t("CasinoCore.sms_verify_send"),
        type: "success",
      });
  }
  async function smsVerify(payload:SmsVerifyRequest){
    fetchSmsVerivfy({
        phone:payload.phone,
        opt:payload.opt
    })
  }
  // 以下為簡訊認證時間設置的方法
  /** 啟用時鐘 */
  function startClock() {
    CLOCK_ID = setInterval(setClock, 1000);
  };

  /** 設定時鐘 */
  function setClock() {
    if (timer.value === 0) return;
    timer.value = timer.value - 1;
  };

  /** 停止時鐘 */
  function stopClock () {
    clearInterval(CLOCK_ID);
  };

  function init() {
    startClock();
  }

  return {
    //data
    timer,
    //methods
    sendSmsVerify,
    smsVerify,
    startClock,
    stopClock,
  };
}

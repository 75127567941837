
// 使用composition API模式定义store
// 全局使用的狀態都放在這裡
export const useTelStore = defineStore("TelStore", () => {
  // 初始状态
  const initState = {
    telPrefix:'',
  };

  //state
  const telPrefix = ref<string>(initState.telPrefix);

  //gatters

  //mutation
  function setTelPrefix(payload: string) {
    telPrefix.value = payload;
  }

  return {
    //data
    telPrefix,
    //methods
    setTelPrefix,
  };
});
import component from "element-plus/es/components/tree-select/src/tree-select-option.mjs";
import path from "path";

export default [
  {
    path: "/",
    component: () => import("@/modules/THEMES/index.vue"),
    children: [
      {
        path: "/APP",
        component: () => import("@/modules/THEMES/pages/APP/index.vue"),
        meta: {
          title: "CasinoCore.APP_DOWNLOAD",
        },
      },
      {
        path: "/login",
        component: () => import("@/modules/THEMES/pages/login/index.vue"),
        meta: {
          title: "CasinoCore.login",
        },
      },
      {
        path: "/socialmedia-redirect",
        component: () =>
          import("@/modules/THEMES/pages/login/SocialMediaLoginRedirectPage.vue"),
      },
      {
        path: "/register/:inviterAccount?/:invite_code?",
        component: () => import("@/modules/THEMES/pages/register/index.vue"),
        meta: {
          title: "CasinoCore.register",
        },
      },
      {
        path: "/smsVerify/:account/:phone?",
        props: true,
        component: () => import("@/modules/THEMES/pages/register/smsVerify/index.vue"),
        meta: {
          title: "CasinoCore.smsVerifyStatus",
        },
      },
      {
        path: "/",
        name: "C5",
        component: () => import("@/modules/THEMES/pages/index.vue"),
        children: [
          {
            /** HOMEPAGE */
            path: "",
            alias: "/home",
            component: () => import("@/modules/THEMES/pages/HOME/index.vue"),
            meta: {
              title: "CasinoCore.HOME",
            },
          },
          {
            // 邀請頁面
            path: "invite",
            component: () => import("@/modules/THEMES/pages/Invite/index.vue"),
          },
          {
            path: "venderGames/:gameType/:venderCode",
            component: () => import("@/modules/THEMES/pages/GAMES/venderGamesPage.vue"),
          },
          {
            path: "sport",
            component: () => import("@/modules/THEMES/pages/GAMES/sport.vue"),
            meta: {
              title: "CasinoCore.SPORT",
            },
          },
          {
            path: "casino",
            component: () => import("@/modules/THEMES/pages/GAMES/casino.vue"),
            meta: {
              title: "CasinoCore.CASINO",
            },
          },
          {
            path: "chess",
            component: () => import("@/modules/THEMES/pages/GAMES/chess.vue"),
            meta: {
              title: "CasinoCore.CHESS",
            },
          },
          {
            path: "gaming",
            component: () => import("@/modules/THEMES/pages/GAMES/gaming.vue"),
            meta: {
              title: "CasinoCore.ESPORT",
            },
          },
          {
            path: "lottery",
            component: () => import("@/modules/THEMES/pages/GAMES/lottery.vue"),
            meta: {
              title: "CasinoCore.LOTTERY",
            },
          },
          {
            path: "egaming",
            component: () => import("@/modules/THEMES/pages/GAMES/egame.vue"),
            meta: {
              title: "CasinoCore.EGAMING",
            },
          },
          // {
          //   path: "fishing",
          //   component: () =>
          //     import(
          //        "@/modules/THEMES/PAGES/GAMES/fishing.vue"
          //     ),
          //   meta: {
          //     title: "CasinoCore.FISHING",
          //   },
          // },
          {
            path: "news/:type?/:block?/:id?",
            alias: "news",
            props: true,
            component: () => import("@/modules/THEMES/pages/news/index.vue"),
          },
          {
            path: "promotion/:type?/:block?",
            alias: "promotion",
            props: true,
            component: () => import("@/modules/THEMES/pages/promotion/index.vue"),
          },
          {
            path: "promotion/:type/:block/:id",
            props: true,
            component: () => import("@/modules/THEMES/pages/promotion/info/index.vue"),
            meta: {
              title: "CasinoCore.PROMOTIONS",
            },
          },
          {
            path: "announcement/:type?/:block?",
            alias: "announcement",
            props: true,
            component: () => import("@/modules/THEMES/pages/announcement/index.vue"),
            meta: {
              title: "CasinoCore.announcement",
            },
          },
          {
            path: "announcement/:type/:block/:id",
            props: true,
            component: () => import("@/modules/THEMES/pages/announcement/info/index.vue"),
            meta: {
              title: "CasinoCore.announcement",
            },
          },
          {
            path: "help/:block?",
            component: () => import("@/modules/THEMES/pages/help/index.vue"),
            props: true,
            meta: {
              title: "CasinoCore.helpCenter",
            },
          },
          {
            path: "/AD/:type/:block/:id",
            props: true,
            component: () => import("@/modules/THEMES/pages/AD/index.vue"),
          },
          {
            path: "myCenter",
            component: () => import("@/modules/THEMES/pages/myCenter/index.vue"),
            children: [
              {
                path: "vip",
                component: () => import("@/modules/THEMES/pages/myCenter/vip/index.vue"),
                meta: {
                  title: "CasinoCore.VIP",
                },
              },
              {
                path: "userInfo",
                component: () => import("@/modules/THEMES/pages/myCenter/userInfo/index.vue"),
                meta: {
                  title: "CasinoCore.memberInfo",
                },
              },
              {
                path: "message",
                component: () => import("@/modules/THEMES/pages/myCenter/message/index.vue"),
              },
              {
                path: "messageDetial",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/message/MessageDetial.vue"),
              },
              {
                path: "setting",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/settingPage/index.vue"),
                children: [
                  {
                    path: "",
                    component: () =>
                      import("@/modules/THEMES/pages/myCenter/settingPage/Setting.vue"),
                  },
                  {
                    path: "password",
                    component: () =>
                      import(
                        "@/modules/THEMES/pages/myCenter/settingPage/SettingPassword.vue"
                      ),
                  },
                  {
                    path: "phone",
                    component: () =>
                      import("@/modules/THEMES/pages/myCenter/settingPage/SettingPhone.vue"),
                  },
                ],
              },
              {
                path: "kyc",
                component: () => import("@/modules/THEMES/pages/kyc/index.vue"),
                children:[
                  {
                    path: "",
                    alies:"kyc",
                    component: () => import("@/modules/THEMES/pages/kyc/Kyc.vue"),
                  },
                  {
                    path: "uploadIDcard",
                    component: () => import("@/modules/THEMES/pages/kyc/UploadIDcard.vue"),
                  },
                  {
                    path: "uploadBankBook",
                    component: () => import("@/modules/THEMES/pages/kyc/UploadBankBook.vue"),
                  },
                ]
              },
              {
                path: "bank",
                component: () => import("@/modules/THEMES/pages/myCenter/bank/index.vue"),
                meta: {
                  title: "CasinoCore.bank",
                },
              },
              {
                path: "deposit",
                component: () => import("@/modules/THEMES/pages/myCenter/deposit/index.vue"),
                meta: {
                  title: "CasinoCore.stored",
                },
              },
              {
                path: "depositResult/:payment?",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/depositResult/index.vue"),
                props: true,
                meta: {
                  title: "CasinoCore.stored",
                },
              },
              {
                path: "depositRecord",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/depositRecord/index.vue"),
                meta: {
                  title: "CasinoCore.record-stored",
                },
              },
              {
                path: "promotionRecord",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/promotionRecord/index.vue"),
                meta: {
                  title: "CasinoCore.record-promotions",
                },
              },
              {
                path: "auction",
                component: () => import("@/modules/THEMES/pages/myCenter/auction/index.vue"),
                meta: {
                  title: "CasinoCore.auction",
                },
              },
              {
                path: "auctionResult/:payment?",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/auctionResult/index.vue"),
                props: true,
                meta: {
                  title: "CasinoCore.auction",
                },
              },
              {
                path: "auctionRecord",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/auctionRecord/index.vue"),
                meta: {
                  title: "CasinoCore.record-auction",
                },
              },
              {
                path: "transfer",
                component: () => import("@/modules/THEMES/pages/myCenter/transfer/index.vue"),
                meta: {
                  title: "CasinoCore.transfer",
                },
              },
              {
                path: "betRecord",
                component: () => import("@/modules/THEMES/pages/myCenter/betRecord/index.vue"),
                meta: {
                  title: "CasinoCore.gameDetail",
                },
              },
              {
                path: "betLedger",
                component: () => import("@/modules/THEMES/pages/myCenter/betLedger/index.vue"),
                meta: {
                  title: "CasinoFrontGames.betLedger",
                },
              },
              {
                path: "reward",
                component: () => import("@/modules/THEMES/pages/myCenter/reward/index.vue"),
                meta: {
                  title: "CasinoCore.rewardGrantRecord",
                },
              },
              {
                path: "bonusRecord",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/bonusRecord/index.vue"),
                meta: {
                  title: "CasinoCore.record-bonus",
                },
              },
              {
                path: "redEnvelopeRecord",
                component: () =>
                  import("@/modules/THEMES/pages/myCenter/redEnvelope/index.vue"),
                meta: {
                  title: "CasinoCore.record-redEnvelope",
                },
              },
            ],
          },
          {
            path: "service",
            component: () => import("@/modules/THEMES/pages/service/index.vue"),
            meta: {
              title: "CasinoCore.customerService",
            },
          },
        ],
      },
    ],
  },
  //      {
  //        path: "/myCenter/promo/list",
  //        component: () =>
  //          import(
  //             "@/modules/THEMES/PAGES/myCenter/promoList/index.vue"
  //          ),
  //      },
  //      {
  //        path: "service/:type/:block/:id",
  //        props: true,
  //        component: () =>
  //          import(
  //             "@/modules/THEMES/PAGES/service/info/index.vue"
  //          ),
  //        meta: {
  //          title: "CasinoCore.customerService",
  //        },
  //      },
  //      {
  //        path: "support",
  //        component: () =>
  //          import( "@/modules/THEMES/PAGES/null.vue"),
  //      },
  //      {
  //        path: "about",
  //        component: () =>
  //          import( "@/modules/THEMES/PAGES/about/index.vue"),
  //        meta: {
  //          title: "CasinoCore.aboutUs",
  //        },
  //      },
] as RouteRecordRaw[];
